import React, { useEffect, useState } from 'react'
import { sendMessage } from '../lib/utils';
function Screen({ title, main, action }) {
  const [openFromIframe, setOpenFromIframe] = useState(false);

  const closePopUp = (e) => {
    e.preventDefault();
    sendMessage("exitedSdk", { data: "exited manually by user" });
  };

  useEffect(() => {
    window === window.parent
      ? setOpenFromIframe(false)
      : setOpenFromIframe(true);
  }, [])

  return (
    <div className="grid grid-rows-3 gap-4 h-5/6 w-11/12 text-center p-4 rounded shadow-xl md:w-9/12 md:h-4/5 xl:w-6/12 bg-white dark:bg-gray-500 relative" >
      {openFromIframe && (
        <button className="absolute top-5 right-5 cursor-pointer px-3 py-1" onClick={closePopUp}>
          <span className='text-gray-600 font-semibold text-xl'>X</span>
        </button>
      )}
      <div className='self-center justify-self-center'>
        <h1 className='text-xl md:text-2xl font-medium'>{title}</h1>
      </div>
      <div className='self-center justify-self-center'>
        {main}
      </div>
      <div className='self-center justify-self-center'>
        {action}
      </div>
    </div >
  )
}

export default Screen
