import React, { useCallback, useEffect } from 'react'
import { Spinner } from 'flowbite-react'
import Screen from '../components/Screen'
import idrndRequest from '../lib/idrnReq';
import { blobToFile, imageDataToBlob, sendMessage } from '../lib/utils';

function Result({ setData, data, accessToken, contractId, images, userId }) {
  const dataSetter = useCallback(data => setData(data), [setData])

  const handleFinalRequest = () => {
    if (images.frontCameraFrame || images.backCameraFrame) {
      let frame = images.frontCameraFrame.frame ? images.frontCameraFrame.frame : images.backCameraFrame.frame;
      let imgBlob = imageDataToBlob(frame);
      imgBlob.then((res => {
        let image = blobToFile(res, "file.png");
        let backImage = blobToFile(new Blob([images.backImage.encodedImage]), 'backImage.png');

        let args = {
          jwtToken: accessToken,
          frontImg: image,
          contractId: contractId,
          userId: userId,
          croppedBackImg: backImage,
          countryCode: data.countryCode,
          documentType: data.documentType
        };

        data.barcode && (args.barcodeResultData = JSON.stringify(data.barcode));

        idrndRequest(args)
          .then((res) => {
            dataSetter(data => ({ ...data, iqrn: res }));
          }).catch(error => sendMessage("userFinishedSdk", { data: {}, message: "FAILURE" }));
      }))
    }
  };

  useEffect(() => {
    if (data && data.iqrn) {
      sendMessage("userFinishedSdk", { data, message: "OK" });
    }
  }, [data])

  useEffect(() => {
    if(images) {
      sendMessage("frontImages", { data: { frontImage: images.frontImage }, message: "OK" });

      sendMessage("backImages", { data: { backImage: images.backImage }, message: "OK" });
    }
  }, [images]);

  useEffect(() => {
    handleFinalRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Screen
      title='Resultado'
      main={
        <div className="text-center">
          <Spinner aria-label="Loading spinner" size="xl" />
          <p className='text-md pt-2'>Espere por favor. Estamos analizando y generando resultados...</p>
        </div>
      }
    />
  )
}

export default Result
