import React from 'react'
import { Button } from 'flowbite-react'

import Screen from '../components/Screen'
import { sendMessage } from '../lib/utils';

function ErrorScreen({ error }) {
    const closePopUp = (e) => {
        e.preventDefault();
        sendMessage('exitedSdk', { data: 'there was an error in the flow' })
    };

    const refreshPage = (e) => {
        e.preventDefault();
        window.location.replace(window.location.pathname + window.location.search + window.location.hash);
    };

    let handler = error.code === 1 ? closePopUp : refreshPage;
    return (
        <Screen
            title='Error'
            main={<p className='text-medium'>{error.reason}.</p>}
            action={<Button onClick={handler}>{error.code === 1 ? 'Cerrar' : 'Reintar'}</Button>} />
    )
}

export default ErrorScreen
